.wrapper {
    width: 160px;
    height: 86px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 370px) {
    .wrapper {
        width: 115px; /* или любое другое значение для экранов менее 400 пикселей */
        height: 86px; /* или любое другое значение для экранов менее 400 пикселей */
    }
}