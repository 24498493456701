:root {
  --jf-primary-color: #F4CB91;
}

body {
  background: rgba(0, 0, 0, 0.82);
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #F4CB91;
  padding-top: 95px;
//font-size: 14px;
//padding-top: 150px;
}

.wrapper {
  background: #242424;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 222;
}

.header {
  margin: 0 auto;
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  width: 95%;

  &__content {
  //padding: 20px 0;
  //display: flex;
  //justify-content: space-between;
  //align-items: flex-start;
  }
}

.btn {
  color: #EBBB6E;
  border: 1px solid #EEA034;
  display: inline-block;
  padding: 10px;
  border-radius: 8px;
  background: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover {
    background: linear-gradient(0deg, rgba(220, 160, 45, 0.4), rgba(220, 160, 45, 0.4)), linear-gradient(180deg, #FDC577 -10.48%, rgba(95, 76, 60, 0.930889) 47.71%, rgba(248, 204, 155, 0.867788) 100.83%, rgba(187, 140, 76, 0.783654) 181.79%, rgba(208, 172, 121, 0.765625) 186.85%, rgba(193, 152, 95, 0) 232.38%);
    border: 1px solid #000000;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.wrapperForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vw;
}

.input {
  &:focus {
    outline: none;
  }
  width: 90%;
  border-radius: 10px;
  border: 2px solid rgba(220, 160, 45, 0.4);
  background: none;
  margin-bottom: 10px;
  padding: 10px;
  color: rgba(244, 199, 135, 1);

  &::placeholder {
    color: rgba(244, 199, 135, 0.3);
  }

  &.error {
    border: 1px solid red;
  }

  &:-webkit-autofill {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }

}