.wrapper {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px
}

@media screen and (max-width: 370px) {
    .wrapper {
        padding: 0;
    }
}